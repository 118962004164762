<template>
    <div id="content">
        <div class="nav pc_nav">
            <button @click="showAlert()" class="pc_notice">受講上の注意</button>&nbsp;
            <button @click="close()" class="pc_exit">終了</button>
        </div>
   
        <div>
            <h1 class="elgTitle">{{ elName }}</h1>
            <iframe id="iframe" width="640" height="407" frameborder="0" scrolling="no"></iframe>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-toggle="modal" aria-hidden="true" v-show="noticeBack"/>   
    <div class="modal-dialog" v-show="elgmovNotice">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title noticeTitle" id="myModalLabel">
                    受講上の注意
                </div>
            </div>
            <div class="modal-body">
                <div>受講進捗を正しく反映させるため、必ず画面右上の『終了』ボタンをクリックして画面を閉じてください。</div>
            </div>
            <div class="ok">
                <button @click="elgmovNotice=!elgmovNotice;noticeBack=!noticeBack" class="elgmovBtn">OK</button>
            </div>
        </div>
    </div>   
</template>

<script >
import { ref, inject, onMounted } from 'vue'
import { useRoute } from "vue-router";

export default {
    name: 'popElgmov',

    setup() {
        const service = inject("service")

        const elgmovNotice = ref(false)
        const noticeBack = ref(false)   

        const elName = ref('');
        const elearningId = ref('');
        const lessonId = ref('');
        const applicationDtm = ref('');
        const getMovPlatform = ref('');
        const route = useRoute();
        var timeOpen;

        var onBeforeUnLoadEvent = false;
        
        function showAlert() {
            elgmovNotice.value=true;
            noticeBack.value=true;
        }

        function close() {
            onBeforeUnLoadEvent = true;
            updateElgmov(false);
        }
        
        function win_close() {
            var browserName=navigator.appName;
            // その他
            if (browserName=="Netscape"){
            window.open("about:blank","_self").close();
            }
            //IE
            if (browserName=="Microsoft Internet Explorer") {
            window.close();
            }
        }

        function initElgmov(){
            if(route.query.elearningId != ''){
                elearningId.value = route.query.elearningId;
            }
            if(route.query.lessonId != ''){
                lessonId.value = route.query.lessonId;
            }
            if(route.query.applicationDtm != ''){
                applicationDtm.value = route.query.applicationDtm;
            }
            let formdata = {elearningId:elearningId.value, applicationDtm:applicationDtm.value, lessonId:lessonId.value}

            service.popelgmov.init(formdata).then((res) => {
                getMovPlatform.value = res.data.movPlatform;
                elName.value = res.data.elearningName;
                document.title = res.data.elearningName;
                const iframe = document.getElementById('iframe');
                const iframedoc = iframe.contentDocument || iframe.contentWindow.document
                iframedoc.write(getMovPlatform.value)
            })
        }
        
        function updateElgmov(exitType) {
            timeOpen = parseInt(Number(performance.now().toString().split('.')[0])/1000);
            let formdata = {elearningId:elearningId.value, applicationDtm:applicationDtm.value, lessonId:lessonId.value, lessonTime:timeOpen}
            if (exitType) {
                window.opener.reConnect(formdata)
            } else {
                service.popelgmov.update(formdata).then((res) => {
                    win_close();
                })
            }
        }
        onMounted(() => {
            initElgmov();
        })
        window.addEventListener('unload', function() {
            if(!onBeforeUnLoadEvent){
		        onBeforeUnLoadEvent = true;
                updateElgmov(true);
            }
        })
       
        return {
            elgmovNotice,
            noticeBack,
            showAlert,
            close,
            initElgmov,
            updateElgmov,
            win_close, 
            elName,
            elearningId,
            applicationDtm,
            lessonId,
            getMovPlatform,
            timeOpen,
        }
    
    },
    
}
</script>

<style lang="scss" scoped>
    body, h1, h2, h3, h4, p {
    margin: 0;
    }
    body {
        color: #333;
        font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    }
    #content {
        max-width: 800px;
        padding: 20px 40px 40px;
        margin: 0 auto;
    }
    h1 {
        font-size: 1.6rem;
        line-height: 1.5em;
        color: #333;
        background-image: none;
        background-repeat: no-repeat;
    }

    ::v-deep h1 {
        color: #333 !important;
        background-image: none !important;
        background-repeat: no-repeat !important;
    }

    @media screen and (max-width: 479px) {
    #content {
        padding: 20px 10px;
    }
    }

    ::v-deep .elgTitle {
        margin-bottom: 40px;
        font-size: 24px;
        font-weight: bold;
    }
    ::v-deep .movie {
        border: 1px solid #ccc;
        max-width: 640px;
        position: relative;
        padding-bottom: 56.25%;
    }
    
    

    .nav {
        right: 0;
        top: 0;
    }

    .pc_nav {
        text-align: right;
    }

    .pc_notice {
        margin-top: 5px;
        height: 25px;
        padding: 0 15px 0 16px;
        line-height: 25px;
        font-size: 1rem;
        letter-spacing: 1px;
        border: none;
        border-radius: 2em;
        cursor: pointer;
        outline: none;
        transition: color .25s,background-color .25s;
        background-color: #3BAFDA;
        color: #FFFFFF;
    }

    .pc_exit {
        margin-top: 5px;
        height: 25px;
        padding: 0 15px 0 16px;
        line-height: 25px;
        font-size: 1rem;
        letter-spacing: 1px;
        border: none;
        border-radius: 2em;
        cursor: pointer;
        outline: none;
        transition: color .25s,background-color .25s;
        background-color: #3BAFDA;
        color: #FFFFFF;
        float: right;
    }
    
    .nav button span::before, .nav button span::after {
        font-size: 1.2rem;
    }

 

    .modal{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1050;
        background-color: rgb(65, 65, 65,0.7);
        overflow: hidden;
        
        outline: 0;
    }

    .modal-dialog{
        position:absolute;
        top: calc((100% - 168px)/2) !important;
        left: calc((100% - 600px)/2) !important;
        height: 168px;
        width: 600px;
        z-index: 1050;
        .modal-content {
            position: relative;
            background-color: #fff;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            border: 1px solid #999;
            border: 1px solid rgba(0,0,0,.2);
            border-radius: 6px;
            outline: 0;
            -webkit-box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
            box-shadow: 0 3px 9px rgb(0 0 0 / 50%);

            .modal-header {
                min-height: 16.43px;
                padding: 15px;
                border-bottom: 1px solid #e5e5e5;
                .noticeTitle {
                    font-weight: bold;
                    text-align: center;
                }
            }
            .modal-body {
                position: relative;
                padding: 15px;
            }
            .ok {
                text-align: center;
                margin-bottom: 20px;
            }
            .elgmovBtn{
                z-index: 1031;
                margin-left: 10px;
                height: 25px;
                padding: 0 15px 0 16px;
                line-height: 25px;
                font-size: 1rem;
                letter-spacing: 1px;
                border: none;
                border-radius: 2em;
                cursor: pointer;
                outline: none;
                transition: color .25s,background-color .25s;
                background-color: #3BAFDA;
                color: #FFFFFF;
            }
        }
    }
</style>
